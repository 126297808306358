import { Link } from "react-router-dom";
import "./dashboard.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { ar } from "date-fns/locale";
import { toast } from "react-toastify";
import NavigationBar from "./navigationBar";

const ShowAllIndustries = () => {
  const [industries, setIndustries] = useState([]);

  if (!localStorage.getItem("username")) {
    window.location.href = "/dashboard";
  }

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await axios.get("/indutries");
        setIndustries(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchIndustries();
  }, []);

  const handleDelete = async (e, industryId) => {
    e.preventDefault();
    try {
      await axios.delete(`/indutries/${industryId}`);
      setIndustries(
        industries.filter((industry) => industry._id !== industryId)
      );
      toast.success("تم الحذف بنجاح");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log(error.request);
        toast.error("حدث خطأ اثناء العملية. يرجى المحاولة مرة اخرى.");
      } else {
        console.log("Error", error.message);
        toast.error("حدث خطأ اثناء العملية. يرجى المحاولة مرة اخرى.");
      }
    }
  };

  return (
    <div className="show-all-exams">
      <div className="container">
        <NavigationBar />
        <div className="table_component" role="region" tabIndex="0">
          <div className="table-head">
            <h1>الصناعات</h1>
          </div>
          <table>
            <thead>
              <tr>
                <th>الرقم</th>
                <th>رابط الصورة</th>
                <th>تاريخ الاضافة</th>
                <th>اجراءات</th>
              </tr>
            </thead>
            <tbody>
              {industries.map((industry, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Link
                      style={{ color: "var(--main-color)" }}
                      to={industry.image}
                      target="_blank"
                    >
                      رابط الصورة
                    </Link>
                  </td>
                  <td>
                    {industry
                      ? format(new Date(industry.createdAt), "yyyy/M/d", {
                          locale: ar,
                        })
                      : ""}
                  </td>
                  <td className="action-icons">
                    <div>
                      <Link to={`/industry`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="show-exam"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="black"
                            d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                          />
                        </svg>
                      </Link>
                      <form>
                        <button onClick={(e) => handleDelete(e, industry._id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="delete-exam"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="black"
                              d="M10 18a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1M20 6h-4V5a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v1H4a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2M10 5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4Zm7 14a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8h10Zm-3-1a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1"
                            />
                          </svg>
                        </button>
                      </form>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowAllIndustries;
