import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./login.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Login = () => {
  const [t] = useTranslation("global");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!username) {
      return toast.error("برجاء كتابة الاسم..!");
    }
    if (!password) {
      return toast.error("برجاء كتابة كلمة المرور..!");
    }

    try {
      const response = await axios.post("/admin", {
        username,
        password,
      });

      console.log(response?.data);
      if (response.data) {
        localStorage.setItem("username", response?.data.admin.username);
        toast.success("تم تسجيل الدخول بنجاح!");
        navigate("/dashboard/add-article");
        window.location.reload();
      }
    } catch (error) {
      toast.error("البيانات خاطئة..!");
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <div className="input-group">
          <label>{t("login.headerOne")}</label>
          <input
            type="text"
            placeholder={t("login.headerOne")}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>{t("login.headerTwo")}</label>
          <input
            type="password"
            placeholder={t("login.headerTwo")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">{t("login.headerThree")}</button>
      </form>
    </div>
  );
};

export default Login;
